import React from 'react';
import { Helmet } from 'react-helmet';
import Components from '../components/components.js';
import { CookieConsent } from '../components/CookieConsent';

class StoryblokEntry extends React.Component {
  static getDerivedStateFromProps(props, state) {
    if (state.story.uuid === props.pageContext.story.uuid) {
      return null;
    }

    return StoryblokEntry.prepareStory(props);
  }

  static prepareStory(props) {
    const story = Object.assign({}, props.pageContext.story);
    story.content = JSON.parse(story.content);

    return { story };
  }

  constructor(props) {
    super(props);

    this.state = StoryblokEntry.prepareStory(props);
  }

  componentDidMount() {
    if (!this.state.trackedPageView) {
      this.setState({ trackedPageView: true });
    }
  }

  render() {
    let story = this.state.story;
    let content = story.content;

    return (
      <>
        <Helmet>
          <title>{story.name} - Swell Energy</title>
          <meta name="title" content={`${story.name} - Swell Energy`} />
          <meta property="og:title" content={`${story.name} - Swell Energy`} />
          <meta property="og:site_name" content="Swell Energy" />
          <meta
            property="og:description"
            content="Solar, Storage, and VPP - Home Energy Systems - Swell Energy"
          />
          <meta
            property="og:image"
            content="https://a.storyblok.com/f/89720/1200x630/e6cca19347/swell-og-3.png"
          />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="630" />
          <meta property="fb:admins" content="swellenergy" />
          <meta name="twitter:title" content={`${story.name} - Swell Energy`} />
          <meta name="twitter:site" content="@swell_energy" />
          <meta
            name="twitter:description"
            content="Solar, Storage, and VPP - Home Energy Systems - Swell Energy"
          />
          <meta
            name="twitter:image"
            content="https://a.storyblok.com/f/89720/1200x630/e6cca19347/swell-og-3.png"
          />
          <link
            rel="image_src"
            href="https://a.storyblok.com/f/89720/1200x630/e6cca19347/swell-og-3.png"
          />
        </Helmet>
        <CookieConsent />
        {React.createElement(Components(content.component), { key: content._uid, blok: content })}
      </>
    );
  }
}

export default StoryblokEntry;
